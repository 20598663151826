import Script from 'next/script';
import React, { useEffect } from 'react';
import { configure } from 'mobx';
import { observer, enableStaticRendering } from 'mobx-react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '@wui/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import * as Sentry from '@sentry/react';

import GlobalContextProvider from 'global-context';
import { setupCsrf } from 'utils/API';
import Nav from 'components/Nav';
import ProtectedPage from 'components/ProtectedPage';
import { ZENDESK_WIDGET_URL, setDepartment, useMinWidthChat } from '@@/utils/constants/chat';
import { googleAnalyticsEffect } from 'utils/google-analytics';
import TitleWatcher from 'components/TitleWatcher';
import env from 'utils/env';

import 'global.css';

setDepartment();

const isSsr = typeof window === 'undefined';
enableStaticRendering(isSsr);

if (!isSsr) {
  Promise.all([env.sentryDsn, env.sentryEnvironment])
    .then(([dsn, environment]) => {
      Sentry.init({ dsn, environment });
    })
    .catch(() => null);
}

configure({ enforceActions: 'never' });

/**
 * Accessibility tool - outputs to devtools console on dev only and client-side only.
 * @see https://github.com/dequelabs/react-axe
 * For full list of a11y rules:
 * @see https://github.com/dequelabs/axe-core/blob/develop/doc/rule-descriptions.md
 */

if (process.env.NODE_ENV !== 'production' && !isSsr) {
  import('react-dom').then(ReactDOM => {
    import('@axe-core/react').then(axe => {
      axe.default(React, ReactDOM, 1000, {});
    });
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const App = ({ Component, pageProps }) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  });

  useEffect(() => {
    // Ensure the CSRF cookie is set.
    setupCsrf();
  }, []);

  useEffect(googleAnalyticsEffect, []);

  useMinWidthChat(600);

  const ProtectComponent = Component.public ? React.Fragment : ProtectedPage;

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalContextProvider>
        <ThemeProvider theme={theme}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />

            <title>MetLife Legal Plans</title>
          </Head>
          <Script id="ze-snippet" src={ZENDESK_WIDGET_URL} strategy="afterInteractive" />

          <CssBaseline />
          <TitleWatcher />
          <ProtectComponent>
            <Nav />
            <Component {...pageProps} />
          </ProtectComponent>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </GlobalContextProvider>
    </QueryClientProvider>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
};

App.defaultProps = {
  pageProps: {},
};

export default observer(App);
